// Imports for external libraries go here.
import { FC } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { AlertBannerProps } from './AlertBanner.types';
import { StyledAlertBanner } from './AlertBanner.styles';
export const alertBannerConfig = {
  emptyLabel: 'AlertBanner',
  isEmpty: () => true,
  resourceType: `/components/content/alertbanner`,
};

// Use named rather than default exports.
export const AlertBannerComp: FC<AlertBannerProps> = (props: AlertBannerProps) => {
  const { title, message, styleclass, showError, isAuthorMode } = props;
  return (
    <StyledAlertBanner
      data-component-name="o-cobrand-alertbanner"
      data-testid="alertbanner"
      className={clsx(styleclass, 'py-3', showError || isAuthorMode ? '' : 'd-none')}
    >
      <InlineMessages title={title} messageList={[message]} type={InlineMessagesType.Warning} />
    </StyledAlertBanner>
  );
};

export const AlertBanner = (props: AlertBannerProps) => {
  return (
    <EditableComponent config={alertBannerConfig} {...props}>
      <AlertBannerComp {...props} />
    </EditableComponent>
  );
};
